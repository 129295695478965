/** @file
 * This file is automatically compiled by Webpack, along with any other files
 * present in this directory. You're encouraged to place your actual application logic in
 * a relevant structure within app/javascript and only use these pack files to reference
 * that code so it'll be compiled.
 *
 * To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
 * layout file, like app/views/layouts/application.html.erb
 *
 * Si se necesita una librería js, la forma recomendada de incluirla es importarla
 * usando imports (import { foo, bar } from 'baz') en el archivo que se necesite.
 *
 * También es posible importar una dependencia por defecto en todos los scripts usando
 * ProvidePlugin (ver environment.js)
 */

/* eslint-disable buk/no-static-import */

// Este archivo debe ser el primero para respetar el CDN
import '../javascripts/lib/webpack-public-path';
import '../javascripts/lib/polyfills';

// Dependencias globales
// - Se exportan a window
// - interactuan con la página de alguna manera (por ejemplo seteando handlers)
// - plugins de jquery que podemos usar
import '../javascripts/vendor/onmount';
import '../javascripts/vendor/rails';
import 'bootstrap';
import '../javascripts/vendor/turbolinks';
import '../javascripts/lib/jquery-ext';

import '../javascripts/vendor/datatables';

import '../javascripts/vendor/numeral';

import '../javascripts/vendor/intercooler';
import '../javascripts/vendor/freshdesk';

import 'blueimp-file-upload/js/jquery.iframe-transport';
import 'blueimp-file-upload';
import 'cocoon-rails';

import '../javascripts/components/adminlte';

import Buk from '../javascripts/lib/buk';
window.Buk = Buk;

import * as numberFormats from '../javascripts/lib/buk/format-number';
import * as currencyFormats from '../javascripts/lib/buk/format-currency';
// TODO: meter dentro de Buk y usarlos desde ahí
window.formatCurrency = currencyFormats.formatCurrency;
window.formatNumber = numberFormats.formatNumber;
window.formatNumberDecimal = numberFormats.formatNumberDecimal;

// Componentes externos
import '../javascripts/components/box-widget';
import '../javascripts/components/push-menu';
import '../javascripts/components/todo-list';
import '../javascripts/components/tree';
import '../javascripts/components/layout';
import '../javascripts/components/datepicker';
import '../javascripts/components/datetimepicker';
import 'bootstrap-timepicker';
import '../javascripts/components/form-validation';
import 'jquery-bootstrap-scrolling-tabs';

// Componentes de buk
import '../javascripts/components/inputs';
import '../../../packs/hcm/colombia/employee_management/app/assets/javascripts/components/pensionado_porcentaje_ccf';
import '../javascripts/components/vue-autoload';
import '../javascripts/components/trim';
import '../javascripts/components/toggle_caret';
import '../javascripts/components/editable';
import '../javascripts/components/modal-notice';
import '../javascripts/components/jquery.autosum';
import '../../../packs/nomina/chile/finiquito/app/assets/javascripts/components/jquery.autoproduct';
import '../javascripts/components/jquery.autocalc';
import '../javascripts/components/rut_mask';
import '../javascripts/components/tablas';
import '../javascripts/components/variable_progress';
import '../javascripts/components/generacion_archivos_progress';
import '../javascripts/components/sortable';
import '../javascripts/components/submit_only';
import '../javascripts/components/nested_form';
import '../javascripts/components/sortable_position';
import '../javascripts/components/destroy_record';
import '../javascripts/components/ckeditor/textinput';
import '../javascripts/components/ckeditor/templateinput';
import '../javascripts/components/bootstrap-toggle';
import '../javascripts/components/select2';
import '../javascripts/components/multi-select-all';
import '../../../packs/plataforma/building_blocks/fiji/app/assets/javascripts';
import '../javascripts/components/multi-select-toggle';
import '../javascripts/components/multi-select-form';
import '../javascripts/components/multi-select-common';
import '../../../packs/plataforma/building_blocks/datatables/app/assets/javascripts/components/datatable/multi-select';
import '../javascripts/components/multi-select-modal';
import '../javascripts/components/modal-show';
import '../javascripts/components/word_filter';
import '../javascripts/components/scroll_on_bottom';
import '../javascripts/components/toggle_inactive';
import '../javascripts/components/special_radio';
import '../../../packs/plataforma/building_blocks/datatables/app/assets/javascripts/components/datatable_chart';
import '../javascripts/components/chart_widget';
import '../javascripts/components/unobrusive-chart';
import '../javascripts/components/auto-change-url';
import '../javascripts/components/toogletoken';
import '../javascripts/components/chart-exporter';
import '../javascripts/components/expand-required-groups';
import '../../../packs/plataforma/core_app/app/assets/javascripts/components/massive_assignment';
import '../javascripts/components/render-async';
import '../javascripts/components/excel-row-import';
import '../javascripts/components/slider';
import '../javascripts/components/preview';
import '../javascripts/components/bux_list';
import '../javascripts/components/paginate-list';
import '../javascripts/components/sort-list';
import '../javascripts/components/open-aside-bar';
import '../../../packs/people_analytics/copilot/app/assets/javascript/components/aside-copilot/aside_copilot_toggle';
import '../javascripts/components/checkbox-required';
import '../javascripts/components/scroll_tabs';
import '../javascripts/components/cocoon_attachments';
import '../javascripts/components/cocoon_tooltips';
import '../javascripts/components/paginator';
import '../javascripts/components/multiform_validations';
import '../../../packs/talent/logbooks/app/assets/javascripts/components/ckeditor/logbook';
import '../../../packs/talent/ninebox/app/assets/javascripts/components/charts/ninebox-v2.js';
import '../javascripts/components/nested_select_filter';
import '../javascripts/components/select-toggle';
import '../javascripts/components/croppie';
import '../javascripts/components/carousel';
import '../../../packs/plataforma/building_blocks/sidebar/app/assets/javascripts/components/sidebar';
import '../../../packs/plataforma/building_blocks/sidebar/app/assets/javascripts/async-chip';
import '../javascripts/components/colorpicker';
import '../javascripts/components/scroll_wrapper';
import '../javascripts/components/photo-swipe.js';
import '../../../packs/people_analytics/copilot/app/assets/javascript/components/aside-copilot/aside_copilot.js';
import '../../../packs/culture/communication/employee_service/app/assets/javascripts/components/employee-service';
import '../../../packs/plataforma/building_blocks/add_participants/app/assets/javascripts/add-participants-widget.js';
import '../javascripts/components/chip';
import '../javascripts/components/avatar_gallery';
import '../../../packs/plataforma/building_blocks/tours/app/assets/javascripts/amplitude-tracking.js';

// Comportamientos aplicables en otros lados
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/modal-link';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/autosize';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/form-toggle';
import '../../../packs/hcm/chile/employee_management/app/assets/javascripts/behaviors/remote_validation';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/behaviors/warn-start-date';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/void-prevent-default';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/clicker';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/disabling';
import '../../../packs/hcm/chile/employee_management/app/assets/javascripts/behaviors/collapsing';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/tooltip-change-on-select';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/print-elements';
import '../../../packs/recruiting/core/app/assets/javascripts/behaviors/forms';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/confirm-box.js';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/slugify';
import '../../../packs/talent/ninebox/app/assets/javascripts/behaviors/popover-ninebox.js';
import '../javascripts/behaviors/assign-form-params-to-link';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/popover';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/tooltip';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/autocomplete-url.js';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/track-form-changes.js';
import '../../../packs/talent/employee_goals/app/assets/javascripts/behaviors/redirect-with-select';
import '../../../packs/talent/employee_goals/app/assets/javascripts/employee-goal';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/behaviors/cocoon-copy-inputs.js';
import '../../../packs/talent/rapidfire/app/assets/javascripts/rapidfire';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/show-hide-more-content.js';
import '../../../packs/recruiting/core/app/assets/javascripts/behaviors/form-toggle-multiple.js';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/limit-number-input';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/toggle-buttons';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/modal-backdrop';
import '../../../packs/nomina/chile/boleta/app/assets/javascripts/behaviors/multiplication';
import '../../../app/assets/javascripts/pages/logged_changes_histories.js';
import '../../../packs/culture/partner_complaint/app/assets/javascripts/behaviors/copy-to-clipboard';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/behaviors/input-max-decimal';
import(
  '../../../packs/hcm/core/employee_management/app/assets/javascripts/behaviors/not-allow-two-selects-subset-each-other'
);
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/csp-nonce';
import '../javascripts/behaviors/paginator-nav';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/amplitude-tracking';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/menu-mobile';
import '../javascripts/behaviors/pickr';
import '../../../packs/nomina/peru/vacaciones/app/assets/javascripts/behaviors/hint-change-on-select';
import '../../../packs/nomina/core/items/app/assets/javascripts/behaviors/active-check-id';
import '../../../packs/plataforma/building_blocks/tours/app/assets/javascripts/index.js';

// JS específicos para ciertas páginas
import '../../../packs/plataforma/building_blocks/background_file_generator/app/assets/javascripts/pages/\
background-file-generation';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/amplitude';
import '../javascripts/pages/assigns';
import '../javascripts/pages/bonos';
import '../../../packs/nomina/core/periodo/app/assets/javascripts/pages/notify-multiple-variable';
import '../javascripts/pages/opciones-progresivas';
import '../../../packs/recruiting/core/app/assets/javascripts/pages/messages_seleccion';
import '../../../packs/plataforma/building_blocks/global_search/app/assets/javascripts/pages/global-search';
import '../../../packs/plataforma/building_blocks/global_search/app/assets/javascripts/pages/happiness-global-search';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/roles';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/area_roles';
import '../../../gems/rails_workflow/app/assets/javascripts/pages/workflow';
import '../../../gems/rails_workflow/app/assets/javascripts/pages/workflow_mails';
import '../../../gems/rails_workflow/app/assets/javascripts/pages/amplitude_workflow';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/orgchart-v2';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/orgchart-employee';
import '../../../packs/hcm/core/attendance/app/assets/javascripts/pages/working_day';
import '../javascripts/pages/document_templates';
import '../javascripts/pages/tab_documentos';
import '../../../packs/nomina/chile/liquidacion/app/assets/javascripts/pages/calcular_liquido';
import '../../../packs/benefit/core/app/assets/javascripts/pages/benefits';
import '../../../packs/benefit/core/app/assets/javascripts/pages/benefits-amplitud';
import '../../../packs/plataforma/building_blocks/filter_query/app/assets/javascripts/pages/amplitude.js';
import '../../../packs/plataforma/core_app/app/assets/javascripts/behaviors/logged_changes_histories.js';
import '../../../packs/people_analytics/building_blocks/custom_report/app/assets/javascripts/pages/\
custom-reports-amplitude';
import '../../../packs/growth/core/marketplace/app/assets/javascripts/pages/marketplace';
import '../../../packs/growth/core/marketplace/app/assets/javascripts/pages/\
marketplace-amplitude';
import '../../../packs/growth/core/upselling/app/assets/javascripts/pages/upselling';
import '@packs/capacitaciones/app/assets/javascripts/pages/courses';
import '../../../packs/culture/recognition/app/assets/javascripts/pages/recognitions.js';
import '../../../packs/culture/recognition/app/assets/javascripts/pages/recognitions-amplitude.js';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/fullcalendar';
import '../javascripts/pages/personals_index_table';
import '../javascripts/pages/finiquito';
import '../javascripts/pages/finiquito/finiquito-chile-amplitude';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/cargas';
import '../javascripts/pages/job-centro-costo';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/sueldo-base-row';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/vacaciones';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/vacaciones/vacaciones-amplitude';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/update_value';
import '../../../packs/recruiting/core/app/assets/javascripts/pages/seleccions';
import '../../../packs/recruiting/core/app/assets/javascripts/pages/seleccion/scorecard.js';
import '../../../packs/recruiting/core/app/assets/javascripts/pages/seleccion/portal_integration.js';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/kpi-data';
import '../../../packs/hcm/core/attendance/app/assets/javascripts/pages/absences';
import '../javascripts/pages/document-signatures';
import '../../../packs/plataforma/building_blocks/importadores/app/assets/javascripts/pages/data-load';
import '../javascripts/pages/generate-multiple-files';
import '../../../packs/hcm/chile/integrations/geo_victoria/app/assets/javascripts/pages/geovictoria.js';
import '../../../packs/hcm/chile/integrations/geo_victoria/app/assets/javascripts/pages/geo_victoria_v3_configs.js';
import '../javascripts/pages/mail_liquidaciones';
import '../javascripts/pages/liquidaciones/liquidaciones-amplitude';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/users_form';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/empresas';
import '../../../packs/people_analytics/building_blocks/custom_report/app/assets/javascripts/pages/\
custom_report_template';
import '../../../packs/people_analytics/building_blocks/custom_report/app/assets/javascripts/pages/predefined';
import '../../../packs/plataforma/building_blocks/reportes/app/assets/javascripts/pages/reportes_amplitude';
import '../../../packs/plataforma/building_blocks/exportadores/app/assets/javascripts/pages/exportadores_amplitude';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/plan.js';
import '../javascripts/pages/cotizacion_adicional';
import '../../../packs/nomina/chile/credit/app/assets/javascripts/pages/credits';
import '../../../packs/plataforma/building_blocks/notificaciones/app/assets/javascripts/pages/notificaciones';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/job';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/pages/resultados_evaluacion';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/pages/evaluation.js';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/pages/evaluation-amplitude.js';
import '../../../packs/talent/evaluation_process/app/assets/javascripts/pages/verifications';
import '../../../packs/culture/survey/open_survey/app/assets/javascripts/pages/open_survey_process';
import '../../../packs/culture/survey/climate_process/app/assets/javascripts/pages/climate';
import '../../../packs/talent/calibration/app/assets/javascripts/pages/calibration';
import '../../../packs/talent/scales/app/assets/javascripts/pages/result-scale';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/employee-summary';
import '../javascripts/pages/employee-documents';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/employee-vacation-config';
import '../javascripts/pages/toggle-linea-liq-form';
import '../../../packs/hcm/core/employee_management/app/assets/javascripts/pages/profile_picture_uploader';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/worker-status-notifications';
import '../../../packs/hcm/core/licence_recovery/app/assets/javascripts/pages/paid-licences';
import '../../../packs/talent/action_plan/app/assets/javascripts/pages/action-plans';
import '../../../packs/talent/onboarding/app/assets/javascripts/pages/onboarding';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/beamer';
import '../../../packs/hcm/core/attendance/app/assets/javascripts/pages/reject_licences';
import '../../../packs/culture/communication/employee_portal/app/assets/javascript/pages/gallery_modal';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/permission_roles_edit';
import '../../../packs/hcm/core/attendance/app/assets/javascripts/pages/licence';
import(
  '../../../packs/hcm/chile/integrations/electronic_licence/app/assets/javascripts/pages/electronic_licence.js'
);
import '../../../packs/hcm/core/attendance/app/assets/javascripts/pages/licence/licence-amplitude';
import '../javascripts/pages/pdf-viewer';
import '../javascripts/pages/enviar-liquidaciones';
import '../../../packs/nomina/chile/boleta/app/assets/javascripts/pages/boletas';
import '../../../packs/nomina/chile/boleta/app/assets/javascripts/pages/boleta-orders';
import '../../../packs/nomina/chile/boleta/app/assets/javascripts/pages/boleta-orders-form';
import '../../../packs/plataforma/building_blocks/custom_attrs/app/assets/javascripts/pages/tipo_atributo_custom';
import '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/base-user-config';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/vacation_form.js';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/vacation_type';
import '../javascripts/pages/importador-bonos-predefinidos';
import '../../../packs/hcm/core/vacacion/app/assets/javascripts/pages/importador-saldo-vacaciones';
import '../../../packs/nomina/colombia/nomina_electronica/app/assets/javascripts/pages/nomina_electronica_eventos';
import '../../../packs/nomina/colombia/nomina_electronica/app/assets/javascripts/pages/integrations_nomina_electronica';
import '../../../packs/culture/communication/employee_portal/app/assets/javascript/pages/mural';
import '../../../packs/culture/communication/employee_portal/app/assets/javascript/pages/mural-advanced-filter-feed.js';
import '../javascripts/pages/form_with_ckeditor';
import '../../../packs/culture/communication/employee_portal/app/assets/javascript/pages/employee-portal';
import '../../../packs/plataforma/building_blocks/pending_task/app/assets/javascript/pages/pending_task.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/modals.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/tooltips.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/payments-table.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/redirect.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/validate-amounts.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/move-alerts.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/terms-and-conditions.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/validate-code.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/amplitude-integration.js';
import '../../../packs/benefit/adelantos/app/assets/javascripts/pages/request.js';
import '@packs/nomina/mexico/integrations/app/assets/javascripts/pages/electronic_payroll.js';
import '../javascripts/pages/reprocesos';
import '../../../packs/culture/communication/employee_portal/app/assets/javascript/pages/communication_resource';
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/update_profile_fields.js'
);
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/manage_html_input.js'
);
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/cargas_default_values.js'
);
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/toggle_sections.js'
);
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/reset_modifications.js'
);
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/form_validations.js'
);
import '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/collapsable_box.js';
import '../javascripts/pages/export/item_transfer_form';
import(
  '../../../packs/hcm/core/profile_modifier/app/assets/javascripts/pages/profile_modifier/manage_pending_request.js'
);
import '../javascripts/pages/layout';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/sessions';
import '../../../packs/nomina/core/periodo_anual/app/assets/javascripts/pages/variable_anual';
import(
  '../../../packs/hcm/chile/integrations/massive_contract/app/assets/javascripts/pages/massive_contracts/datatable'
);
import(
  '../../../packs/hcm/chile/integrations/massive_contract/app/assets/javascripts/pages/massive_contracts/filters'
);
import(
  '../../../packs/hcm/chile/integrations/massive_contract/app/assets/javascripts/pages/massive_contracts/change_status'
);
import '../javascripts/pages/acciones_empleados';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/login';
import '../../../packs/plataforma/building_blocks/general/app/assets/javascripts/pages/generals';
import '../../../packs/plataforma/building_blocks/importadores/app/assets/javascripts/pages/amplitude-data-load';
import '../../../packs/plataforma/core_app/app/assets/javascripts/pages/hotjar';
import '../../../packs/plataforma/analytics/app/assets/javascripts/pages/google_analytics.js';
import '../../../packs/hcm/core/organization/app/assets/javascripts/pages/roles-amplitude';
import '../../../packs/culture/partner_complaint/app/assets/javascripts/pages/partner_complaint';
import '../javascripts/pages/amplitude_procesos';
import '../javascripts/pages/amplitude_reprocesos';
import '../../../packs/hcm/core/integrations/buk_asistencia/app/assets/javascripts/pages/bukas_run';
import '../../../packs/plataforma/building_blocks/filter_query/app/assets/javascripts/pages/filter_query';
import '../../../packs/plataforma/core_app/app/assets/javascripts/components/layouts/header';
import '../../../packs/hcm/core/logbooks/app/assets/javascripts/pages/logbooks';

// JS especifico para buk starter
import '../../../packs/plataforma/starter/app/assets/javascripts/pages/onboarding';
// JS especifico para Free Trial en empresas XS México
import '../../../packs/growth/core/marketplace/app/assets/javascripts/productFruits.js';

// React components
import '../javascripts/react_components';

// Integracion Dataexpress
import '../javascripts/pages/data-express';

// JS para pagos retroactivos
import '../../../packs/nomina/core/pagos_retroactivos/app/assets/javascripts/retroactive_job_change_form';
